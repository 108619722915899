import {
  types,
  Instance,
  SnapshotOut,
  IMSTArray,
  ISimpleType,
} from "mobx-state-tree";
import api from "services/api/api";

const Coordinates = types.model("coordinates").props({
  coordinates: types.maybeNull(types.array(types.number)),
});

const Center = types.model("Center").props({
  lat: types.number,
  lng: types.number,
});

const Alarms = types.model("Alarms").props({
  deviceType: types.maybeNull(types.string),
  IDMeter: types.maybeNull(types.string),
  location: types.maybeNull(Coordinates),
  serial: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  connected: types.maybeNull(types.boolean),
  group: types.maybeNull(types.string),
});

const Maps = types.model("Maps").props({
  devices: types.array(Alarms),
  center: Center,
});

export const MapModel = types
  .model("Maps")
  .props({
    alarms: Maps,
    isLoading: types.boolean,
    selectedGroups: types.array(types.string),
    criteria: types.optional(types.string, ""),
  })
  .views((self) => ({
    filterDevices() {
      const lowerCaseCriteria = self.criteria?.toLowerCase();
      const filteredDevices = self.alarms.devices.filter((item: any) => {
        const isGroupMatch =
          !self.selectedGroups.length ||
          self.selectedGroups.includes(item?.group);
        const isCriteriaMatch =
          self.criteria === undefined ||
          self.criteria === "" ||
          item.id?.toLowerCase().includes(lowerCaseCriteria) ||
          item.IDMeter?.toLowerCase().includes(lowerCaseCriteria) ||
          item.serial?.toLowerCase().includes(lowerCaseCriteria);
        return isGroupMatch && isCriteriaMatch;
      });
      return filteredDevices;
    },
    filterDevicesMap() {
      const lowerCaseCriteria = self.criteria?.toLowerCase();
      const filteredDevices = self.alarms.devices.filter((item: any) => {
        const isGroupMatch =
          !self.selectedGroups.length ||
          self.selectedGroups.includes(item?.group);
        return isGroupMatch;
      });
      const filterDevicesWithSerial = self.alarms.devices.filter(
        (item: any) => {
          return item.serial?.toLowerCase() === lowerCaseCriteria;
        }
      );
      return filterDevicesWithSerial.length !== 0
        ? filterDevicesWithSerial
        : filteredDevices;
    },
  }))

  .actions((self) => {
    function setAlarms(alarms: any) {
      self.alarms = alarms;
    }
    function setLoading(isLoading: any) {
      self.isLoading = isLoading;
    }
    function setSelectedGroups(group: string) {
      if (self.selectedGroups.findIndex((item) => item === group) >= 0) {
        self.selectedGroups.remove(group);
      } else {
        self.selectedGroups.push(group);
      }
    }
    function setGroups(group: IMSTArray<ISimpleType<string>>) {
      self.selectedGroups = group;
    }
    function setCriteria(criteria: string) {
      self.criteria = criteria;
    }
    return {
      setAlarms,
      setLoading,
      setSelectedGroups,
      setGroups,
      setCriteria,
    };
  })
  .actions((self) => ({
    getLocations: async (params: any) => {
      try {
        self.setLoading(true);
        const maps = api.maps;
        const response = await maps.getDevices(params);
        self.setAlarms(response);
        self.setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type MapModelType = Instance<typeof MapModel>;
export type Map = MapModelType;
type MapSnapshotType = SnapshotOut<typeof MapModel>;
export type MapSnapshot = MapSnapshotType;
export const mapDefaultModel = () =>
  types.optional(MapModel, {
    alarms: {
      devices: [],
      center: {
        lat: 0,
        lng: 0,
      },
    },
    isLoading: false,
  });
