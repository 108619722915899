import ErrorBoundaryUI from "components/common/error";
import { RootStoreProvider, rootStore } from "models";
import config from "config";
import { Provider } from "react-redux";
import { Provider as RoolbarProvider, ErrorBoundary } from "@rollbar/react";
import store from "store";
import App from "App";
import { pageViewTracking, run } from "config/ga";

export const ConnectedApp = () => {
  // run google analytics
  run();

  // page view tracking
  pageViewTracking({
    pageview: window.location.pathname + window.location.search,
  });

  return (
    <RoolbarProvider config={config.rollBar}>
      <ErrorBoundary fallbackUI={ErrorBoundaryUI}>
        <Provider store={store}>
          <RootStoreProvider value={rootStore}>
            <App />
          </RootStoreProvider>
        </Provider>
      </ErrorBoundary>
    </RoolbarProvider>
  );
};
