const config = {
  api: {
    baseUrl: process.env.REACT_APP_BASEURL,
  },
  rollBar: {
    accessToken: process.env.REACT_APP_ROLLBARTOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.REACT_APP_ENVIRONMENT || "development",
    },
  },
  map: {
    accessToken: process.env.REACT_APP_GOOGLEMAPSKEY,
  },
  google: {
    clientId: process.env.REACT_APP_GOOGLECLIENTID || "",
  },
  services: {
    waterMeters: process.env.REACT_APP_WATERMETERS || "",
  },
};

export default config;