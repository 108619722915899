import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api/api";

const Coordinates = types.model("coordinates").props({
  coordinates: types.array(types.number),
});

const AlarmModel = types.model("Alarm").props({
  temperature: types.maybeNull(types.number),
  humidity: types.maybeNull(types.number),
  id: types.maybeNull(types.string),
  atmosphericPressure: types.maybeNull(types.number),
  pollution: types.maybeNull(types.number),
  PM10: types.maybeNull(types.number),
  PM25: types.maybeNull(types.number),
  lux: types.maybeNull(types.number),
  noise: types.maybeNull(types.number),
  connected: types.maybeNull(types.boolean),
  connectedDate: types.maybeNull(types.string),
  group: types.maybeNull(types.string),
  location: types.maybeNull(Coordinates),
  serial: types.maybeNull(types.string),
  PDirection: types.maybeNull(types.union(types.string, types.number)),
  windSpeed: types.maybeNull(types.number),
  rainFall: types.maybeNull(types.number),
});

const Location = types.model("Location").props({
  lat: types.number,
  lng: types.number,
});

export const WeatherModelAlarm = types
  .model("Weather")
  .props({
    alarms: types.optional(types.array(AlarmModel), []),
    locationSelected: types.maybeNull(Location),
    idSelected: types.maybeNull(types.string),
    center: Location,
    isLoading: types.boolean,
  })
  .views((self) => ({
    filterValues(value: string | null) {
      if (value === null) return self.alarms;
      return self.alarms.filter(
        (alarm) =>
          alarm.id?.toLowerCase().includes(value.toLowerCase()) ||
          alarm.temperature?.toString().includes(value.toLowerCase()) ||
          alarm.humidity?.toString().includes(value.toLowerCase()) ||
          alarm.atmosphericPressure?.toString().includes(value.toLowerCase()) ||
          alarm.pollution?.toString().includes(value.toLowerCase()) ||
          alarm.noise?.toString().includes(value.toLowerCase()) ||
          alarm.group?.toString().includes(value.toLowerCase())
      );
    },
  }))
  .actions((self) => {
    function setAlarms(alarms: any) {
      self.alarms = alarms;
    }
    function setlocationSelected(cordinates: any) {
      self.locationSelected = cordinates;
    }
    function setId(id: any) {
      self.idSelected = self.idSelected === id ? null : id;
    }
    function setCenter(coordinates: any) {
      self.center = coordinates;
    }
    function setLoading(loading: any) {
      self.isLoading = loading;
    }
    return {
      setAlarms,
      setLoading,
      setCenter,
      setlocationSelected,
      setId,
    };
  })
  .actions((self) => ({
    getDevices: async () => {
      try {
        self.setLoading(true);
        const alarmsApi = api.weather;
        const response = await alarmsApi.getDevice();
        self.setAlarms(response?.devices);
        self.setCenter(response?.center);
        self.setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type WeatherModelAlarmType = Instance<typeof WeatherModelAlarm>;
export type Weather = WeatherModelAlarmType;
type WeatherSnapshotType = SnapshotOut<typeof WeatherModelAlarm>;
export type WeatherSnapshot = WeatherSnapshotType;
export const weatherDefaultModel = () =>
  types.optional(WeatherModelAlarm, {
    alarms: [],
    locationSelected: null,
    idSelected: null,
    center: { lat: 0, lng: 0 },
    isLoading: false,
  });
