import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api/api";

const Coordinates = types.model("coordinates").props({
  coordinates: types.array(types.number),
});

const AlarmModel = types.model("Alarm").props({
  id: types.maybeNull(types.string),
  serial: types.maybeNull(types.string),
  moveDetectionDate: types.maybeNull(types.string),
  moveDetection: types.maybeNull(types.boolean),
  connectedDate: types.maybeNull(types.string),
  connected: types.maybeNull(types.boolean),
  temperature: types.maybeNull(types.number),
  location: types.maybeNull(Coordinates),
  group: types.maybeNull(types.string),
});

const Location = types.model("Location").props({
  lat: types.number,
  lng: types.number,
});

const centerPoint = types.model("CenterPoint").props({
  location: Coordinates,
});

export const FreezerModel = types
  .model("Freezer")
  .props({
    alarms: types.optional(types.array(AlarmModel), []),
    locationSelected: types.maybeNull(Location),
    idSelected: types.maybeNull(types.string),
    centerPoint: types.maybeNull(centerPoint),
    isLoading: types.boolean,
  })
  .views((self) => ({
    filterValues(value: string | null) {
      if (value === null) return self.alarms;
      return self.alarms.filter(
        (alarm) =>
          alarm?.id?.toLowerCase().includes(value.toLowerCase()) ||
          alarm?.temperature?.toString().includes(value.toLowerCase()) ||
          alarm?.moveDetectionDate?.toLowerCase().includes(value.toLowerCase())
      );
    },
  }))
  .actions((self) => {
    function setAlarms(alarms: any) {
      self.alarms = alarms;
    }
    function setlocationSelected(cordinates: any) {
      self.locationSelected = cordinates;
    }
    function setId(id: any) {
      self.idSelected = self.idSelected === id ? null : id;
    }
    function setCenter(coordinates: any) {
      self.centerPoint = coordinates;
    }
    function setLoading(loading: any) {
      self.isLoading = loading;
    }
    return {
      setAlarms,
      setLoading,
      setCenter,
      setlocationSelected,
      setId,
    };
  })
  .actions((self) => ({
    getAlarms: async () => {
      try {
        self.setLoading(true);
        const alarmsApi = api.freezer;
        const response = await alarmsApi.getDevice();
        self.setAlarms(response?.answer.device);
        self.setCenter(response?.answer.centerPoint);
        self.setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type FreezerModelType = Instance<typeof FreezerModel>;
export type Freezer = FreezerModelType;
type FreezerSnapshotType = SnapshotOut<typeof FreezerModel>;
export type FreezerSnapshot = FreezerSnapshotType;
export const freezerDefaultModel = () =>
  types.optional(FreezerModel, {
    alarms: [],
    locationSelected: null,
    idSelected: null,
    centerPoint: null,
    isLoading: false,
  });
