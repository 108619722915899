import "react-app-polyfill/stable";
import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import { ConnectedApp } from "ConnectedApp";

ReactDOM.render(
  <React.StrictMode>
    <ConnectedApp />
  </React.StrictMode>,
  document.getElementById("root")
);
