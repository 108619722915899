import { ApiResponse } from "apisauce";
import { Api } from "./api";
import { getGeneralApiProblem } from "./api-problem";

export class MapApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }
  async getDevices(params: any): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/map`,
        params
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) {
      return { kind: "bad-data" };
    }
  }
}
