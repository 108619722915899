import { Box, Button } from "@mui/material";
import React from "react";

const ErrorBoundaryUI: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap=".5rem"
      marginTop="1%"
    >
      Ocurrio un error inesperado
      <Button
        variant="contained"
        style={{ width: "9rem" }}
        onClick={() => {
          localStorage.clear();
          window.location.href = "/";
        }}
      >
        Ir a login
      </Button>
    </Box>
  );
};

export default ErrorBoundaryUI;
