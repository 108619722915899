import { Instance, SnapshotOut, types } from "mobx-state-tree";
import api from "services/api/api";

const chartData = types.model({
  date: types.maybeNull(types.string),
  vehicles: types.maybeNull(types.number),
});

const TransitModel = types.model("Transit").props({
  // New data
  chartData: types.optional(types.array(chartData), []),
  connected: types.maybeNull(types.boolean),
  countPerTime: types.maybeNull(types.number),
  // Old data
  // title: types.maybeNull(types.string),
  // lineColor1: types.maybeNull(types.string),
  // lineColor2: types.maybeNull(types.string),
  // passCounterLane1: types.maybeNull(types.number),
  // averangeSpeedLane1: types.maybeNull(types.number),
  // maximunSpeedLane1: types.maybeNull(types.number),
  // staticOccupancyStatusLane1: types.maybeNull(types.boolean),
  // passCounterLane2: types.maybeNull(types.number),
  // averangeSpeedLane2: types.maybeNull(types.number),
  // maximunSpeedLane2: types.maybeNull(types.number),
  // staticOccupancyStatusLane2: types.maybeNull(types.boolean),
  // chartLine2: types.maybeNull(types.number),
  // energyState: types.maybeNull(types.boolean),
});

export const TransitsModel = types
  .model("Transits")
  .props({
    transit: types.maybeNull(TransitModel),
  })
  .actions((self) => {
    function setTransit(transit: any) {
      self.transit = transit;
    }
    return {
      setTransit,
    };
  })
  .actions((self) => ({
    getDevice: async () => {
      try {
        const device = api.transit;
        const response = await device.getDevice();
        self.setTransit(response);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type TransitsModelType = Instance<typeof TransitsModel>;
export type Transits = TransitsModelType;
type TransitsSnapshotType = SnapshotOut<typeof TransitsModel>;
export type TransitsSnapshot = TransitsSnapshotType;
export const transitDefaultModel = () =>
  types.optional(TransitsModel, {
    transit: {},
  });
