import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api/api";

const Coordinates = types.model("coordinates").props({
  coordinates: types.array(types.number),
});

const AlarmModel = types.model("Alarm").props({
  address: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  serial: types.maybeNull(types.string),
  location: types.maybeNull(Coordinates),
  zone: types.maybeNull(types.string),
  connected: types.maybeNull(types.boolean),
  connectedDate: types.maybeNull(types.string),
  group: types.maybeNull(types.string),
  electricCurrent: types.maybeNull(types.number),
  maxFlow: types.maybeNull(types.number),
  unit: types.maybeNull(types.string),
  lastFlow: types.maybeNull(types.number),
});

const Location = types.model("Location").props({
  lat: types.number,
  lng: types.number,
});

export const Sense_4_20mAModel = types
  .model("Sense_4_20mA")
  .props({
    alarms: types.optional(types.array(AlarmModel), []),
    locationSelected: types.maybeNull(Location),
    idSelected: types.maybeNull(types.string),
    center: Location,
    isLoading: types.boolean,
  })
  .views((self) => ({
    filterValues(value: string | null) {
      if (value === null) return self.alarms;
      return self.alarms.filter(
        (alarm) =>
          alarm.id?.toLowerCase().includes(value.toLowerCase()) ||
          alarm.address?.toLowerCase().includes(value.toLowerCase())
      );
    },
  }))
  .actions((self) => {
    function setAlarms(alarms: any) {
      self.alarms = alarms;
    }
    function setlocationSelected(cordinates: { lng: number; lat: number }) {
      self.locationSelected = cordinates;
    }
    function setId(id: string) {
      self.idSelected = self.idSelected === id ? null : id;
    }
    function setCenter(center: any) {
      self.center = center;
    }
    function setLoading(loading: boolean) {
      self.isLoading = loading;
    }
    return {
      setAlarms,
      setLoading,
      setCenter,
      setlocationSelected,
      setId,
    };
  })
  .actions((self) => ({
    getDevices: async () => {
      try {
        self.setLoading(true);
        const alarmsApi = api.sense_4_20mA;
        const response = await alarmsApi.getDevice();
        self.setCenter(response?.center);
        self.setAlarms(response?.devices);
        self.setLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
  }));

type Sense_4_20mAModelType = Instance<typeof Sense_4_20mAModel>;
export type Sense_4_20mA = Sense_4_20mAModelType;
type Sense_4_20mASnapshotType = SnapshotOut<typeof Sense_4_20mAModel>;
export type Sense_4_20mASnapshot = Sense_4_20mASnapshotType;
export const sense_4_20mADefaultModel = () =>
  types.optional(Sense_4_20mAModel, {
    alarms: [],
    locationSelected: null,
    idSelected: null,
    isLoading: false,
    center: { lat: 0, lng: 0 },
  });