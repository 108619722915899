import { ApiResponse } from "apisauce";
import { Api } from "./api";
import { getGeneralApiProblem } from "./api-problem";

export class CustomApi {
  private api: Api;
  service: string;

  constructor(api: Api, service: string) {
    this.api = api;
    this.service = service;
  }

  async getCard(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `${this.service}/card`
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) {
      return { kind: "bad-data" };
    }
  }

  async getDevice(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `${this.service}/device`
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) {
      return { kind: "bad-data" };
    }
  }

  async getData(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `${this.service}`
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) {
      return { kind: "bad-data" };
    }
  }
}
